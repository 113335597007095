<template>
  <div class="container">
    <Header/>
    <div :class="data.machineStatus === 3 ? 'wrap wrap-down' : 'wrap' ">
      <div class="loading" v-if="data.isLoading">
        <a-spin />
      </div>
      <div class="content" v-else>
        <TableHeader :title="data.machineName">
          <template v-slot:tab-item>
            <div :class="data.isTabAction === 1 ? 'tab-sub tab-action' : 'tab-sub'" @click="handleSwitchTable(1)">带货单</div>
            <div :class="data.isTabAction === 2 ? 'tab-sub tab-action' : 'tab-sub'" @click="handleSwitchTable(2)">机器上架单</div>
          </template>
        </TableHeader>
        <!-- 补货推荐单 -->
        <SimpleTable v-if="data.isTabAction === 1">
          <template v-slot:table-th>
            <tr>
              <th>商品名称</th>
              <th>推荐带货数量</th>
              <th>配货池数量</th>
              <th>需调拨数量</th>
              <th>可调拨数量</th>
            </tr>
          </template>
          <template v-slot:table-td>
            <tr v-for="item in data.replenishmentOrder" :key="item.goods_code">
              <td>{{ item.goods_name }}</td>
              <td>{{ item.number }}</td>
              <td>{{ item.csc_kc }}</td>
              <td>{{ item.xdb_number }}</td>
              <td>{{ item.kdb_number }}</td>
            </tr>
          </template>
        </SimpleTable>
        <!-- 机器上架单 -->
        <SimpleTable v-else>
          <template v-slot:table-th>
            <tr>
              <th>产品系列名称</th>
              <th>类型</th>
              <th>货架</th>
              <th>数量</th>
            </tr>
          </template>
          <template v-slot:table-td>
            <tr v-for="item in data.saleOrder" :key="item.goods_code">
              <td>{{ item.goods_name }}</td>
              <td>{{ item.status === 1 ? '上架' : '下架' }}</td>
              <td>{{ item.slot_id }}</td>
              <td>{{ item.number }}</td>
            </tr>
          </template>
        </SimpleTable>
      </div>
    </div>
    <div class="footer" v-show="!data.isLoading">
      <div class="btn">
        <a-button :class="data.machineStatus === 3 ? 'btn-item btn-back btn-lengthen' : 'btn-item btn-back'" size="large">
          <router-link to="/">返回</router-link>
        </a-button>
        <a-button class="btn-item btn-operate" type="primary" size="large" :loading="data.btnLoading" @click="handleConfirmRevise()" v-if="data.machineStatus !== 3">
          {{ data.machineStatusArr[data.machineStatus] }}
        </a-button>
        <a-modal v-model:visible="data.visible" :title="data.machineName" @ok="handleConfirmTransfer">
          <SimpleTable>
            <template v-slot:table-th>
              <tr>
                <th>商品名称</th>
                <th>调拨数量</th>
                <th>调出机器</th>
              </tr>
            </template>
            <template v-slot:table-td>
              <tr v-for="item in data.transferList" :key="item">
                <td>{{ item.goods_name }}</td>
                <td>{{ item.number }}</td>
                <td>{{ item.target }}</td>
              </tr>
            </template>
          </SimpleTable>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import Header from '../../components/Header/Header.vue'
import TableHeader from '../../components/Table/TableHeader.vue'
import SimpleTable from '../../components/Table/SimpleTable.vue'
import {
  apiGetStockProduct,
  apiConfirmReceive,
  apiConfirmOnline,
  apiWantToFinish
} from '@/request/apis/Task/TaskListApi'

export default defineComponent({
  name: 'TaskOperate',
  components: { Header, TableHeader, SimpleTable },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const data = reactive({
      machineId: route.params.machine_id,
      userId: store.state.user.user_id,
      isLoading: true,
      isTabAction: 1,
      replenishmentOrder: [],
      saleOrder: [],
      visible: false,
      isTransfer: false, // 是否需要调拨
      transferList: [],
      machineName: '', // title
      machineStatus: 0,
      machineStatusArr: ['确认领取', '我要上架', '我要完成', null],
      btnLoading: false
    })

    onMounted(() => {
      apiGetStockProduct(data.machineId, data.userId).then(res => {
        data.replenishmentOrder = res.data.data.take
        data.saleOrder = res.data.data.put
        data.machineName = res.data.data.machine_name
        data.machineStatus = res.data.data.status
        data.isLoading = false
        if (JSON.stringify(res.data.data.transfer) !== '[]') {
          data.isTransfer = true
          data.transferList = res.data.data.transfer
        }
      })
    })

    const handleConfirmRevise = () => {
      if (!data.btnLoading) {
        data.btnLoading = true
        switch (data.machineStatus) {
          case 0: receive(); break // 待领取
          case 1: implement(); break // 待执行
          case 2: replenishment(); break // 补货中
          case 3: finish(); break // 已完成
        }
      }
    }

    /**
     *  @receive 待领取-判断是否需要调拨
     *  @handleApiConfirmReceive 调拨/无调拨 接口
     *  @handleConfirmTransfer 调拨弹窗确认按钮事件
     */
    const receive = () => {
      // 判断是否需要调拨
      if (!data.isTransfer) {
        handleApiConfirmReceive('确认领取') // 直接确认
      } else {
        data.visible = true
      }
    }
    const handleApiConfirmReceive = hint => {
      apiConfirmReceive(data.machineId, data.transferList, data.transferList).then(res => {
        if (res.data.code === '0') {
          message.success(`${hint}成功`)
          router.push({ name: 'TaskList' })
        } else {
          message.error(`${hint}失败`)
        }
      })
    }
    const handleConfirmTransfer = () => {
      data.visible = false
      handleApiConfirmReceive('调拨')
    }

    /**
     * @implement 待执行-确认上架
     */
    const implement = () => {
      // 确认上架
      apiConfirmOnline(data.machineId).then(res => {
        if (res.data.code === '0') {
          message.success('确认上架成功')
          router.push({ name: 'TaskList' })
        } else {
          message.error('确认上架失败')
        }
      })
    }

    /**
     * @replenishment 补货中-我要完成
     */
    const replenishment = () => {
      // 我要完成
      apiWantToFinish(data.userId, data.machineId).then(res => {
        if (res.data.code === '0') {
          message.success('完成任务')
          router.push({ name: 'TaskList' })
        } else {
          message.error('操作失败')
        }
      })
    }

    /**
     * @finish 已完成
     */
    const finish = () => {
      console.log('finish')
    }

    /**
     * Tag 选项卡切换
     * @param type
     */
    const handleSwitchTable = type => (data.isTabAction = type)

    return {
      data,
      handleSwitchTable,
      handleConfirmRevise,
      handleConfirmTransfer
    }
  }
})

</script>

<style scoped lang="scss">
@import "../../style/global";

.container {
  height: 100vh;

  .wrap {
    display:flex;
    flex-direction:column;
    height: calc(100% - 3rem);
    background-color: #fff;
    border-radius: .1rem;
    font-size: .35rem;
    overflow: auto;
    box-sizing: border-box;
    border-width: .2rem;
    border-style: solid;
    border-color: white;

    .loading {
      text-align: center;
      background: #FFFFFF;
      border-radius: 4px;
      padding: .5rem .2rem;
      margin: 1rem 0;
    }

    .content {
      .tab-sub {
        width: 50%;
        text-align: center;
        color: #545454;
        background-color: #ccc;
      }
      .tab-action {
        background-color: #fff;
        color: $themeColor;
        border: 1px solid $themeColor;
      }
    }
  }

  .wrap-down {
    background: #fff url('../../assets/u721.png') no-repeat center;
  }

  .footer{
    width: 100%;

    .btn {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;

      .btn-item {
        width: 50%;
        height: 1.3rem;
        font-size: 0.5rem;
        @include themeFontFamily($fontFamilyGBT)
      }

      .btn-back {
        color: #717171;
        background-color: #D1D1D1;
      }
      .btn-lengthen {
        width: 100%;
      }

      .btn-operate {
        color: rgba(255, 255, 255, 0.93);
        background-color: $themeColor;
        border: none;
      }
    }
  }
}
</style>
