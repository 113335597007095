
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableHeader',
  components: {},
  props: { title: String },
  setup () {
    return {}
  }
})

